<!-- resolver problea no get é so coloca um if para resolver o problema -->
<script>
//import Multiselect from "vue-multiselect";
import required from "vuelidate/lib/validators";
import Multiselect from "vue-multiselect";
import { http } from "../../../helpers/easyindustriaapi/config";
import composicao from "./produto_composicao";
//import Menu1 from "./menu.vue";

export default {
  //components: { Multiselect },
  props: {
    showModal: {
      type: Boolean,
    },
    tipos_produtos: { type: Array },
    fornecedores: { Array },
    compradores: { Array },
    produtos2: { Array },
    p_contas: { Array },
    locais_estoques: { Array },
  },
  components: {
    Multiselect,
    composicao,
    // Menu1,
    //estabelece
  },
  data() {
    return {
      locais_estoques2: [
        {
          id: 1,
          nome: "local 1",
        },
      ],
      select_estoques: null,
      tipo_bollean: false,
      loader: { get: false },
      titleBody: "Incluir Produto Item",
      tabelas: {
        ttp_nome: null,
      },
      items: ["pedido1", "pedido2", "pedido3"],
      order: {},
      dateselected_order: null,
      dateselected_arrival: null,
      hide: false,
      amount: null,
      tipo_selecionado: null,
      supplier_name_selected: null,
      supplier_code_selected: null,
      note: null,
      value: null,
      Feedstock: null, // materia prima
      value_order: null, // valor_pedido
      situation1: null,
      situation2: null,
      Buyer: null, // comprador
      Comments: null,
      pre_ped: {
        tipo_id: 1,
        objeto_tipo_id: null,
        fornecedor_id: 1,
        objeto_fornecedor_id: null,
        data: null,
        previsao_chegada: null,
        nnf: null,
        valor: 0,
        situacao_id: null,
        comprador_id: 1,
        objeto_comprador_id: null,
        obervacoes: "nada",
      },
      produtos: [],
      fornecedoresReceb: [],
      pesquisa_nome: "",
      nome: "",
      codigo: "",
      pesquisa_codigo: "",
      hide1: false,
      hide2: false,
      timer: null,
      list_products: [],
      produto_selecionado: null,
      seleciona_prod: null,

      valor_select_prod: null,
      modal2: false,
      nome_produto: "",
      // para pegar todas as composiçõa
      // em um aarrya de objetos;
      list_composicoes: [],
      validate_composition: false,
      contador: 0,
      outros: false,
      input_disabled: true,
      tot: "total",
      sigla: "+",
      sub_total: null,
      total1: null,
      outro_valor: {
        frete: null,
        desconto: null,
        funrural1: null,
        funrural2: null,
      },
      submit_item: false,
      item: {
        id: null,
        mt_prima: null,
        produto_id: null,
        lote: null,
        qtd: null,
        preco: null,
        valor: null,
        desconto:null,
        frete:null,
        funrural2:null,
        local_estoque_id: null,
      },
      fields: [
        {
          label: "ID",
          key: "id",
          tdClass: "text-right",
          thClass: "text-center",
        },
        {
          label: "*Materia Prima",
          key: "mt_prima",
          tdClass: "text-center",
          thClass: "text-center",
        },
        {
          label: "Lote",
          key: "lote",
          tdClass: "text-center",
          thClass: "text-center",
        },
        {
          label: "*Local Estoques",
          key: "local_estoque_id",
          tdClass: "text-center",
          thClass: "text-center",
        },
        {
          label: "*Qantidade",
          key: "qtd",
          tdClass: "text-center",
          thClass: "text-center",
          formatter: "formatterCurrBR",
        },
        {
          label: "*Preço",
          key: "preco",
          tdClass: "text-center",
          thClass: "text-center",
        },
        {
          label: "*Valor",
          key: "valor",
          tdClass: "text-center",
          thClass: "text-center",
        },
        {
          label: "Ações",
          key: "excluir",
          tdClass: "text-center",
          thClass: "text-center",
        },
      ],
      items_push: [],
      total: null,
      total2: null,
      subtotal: null,
      soma: null,
      menu_abre: false,
      inicial: {},
      p_contas1: [],
      tipos_produtos2: [
        {
          id: 1,
          nome: "materia Prima Comum",
        },
        {
          id: 2,
          nome: "materia  Prima não Comum",
        },
        {
          id: 3,
          nome: "Produto",
        },
      ],
      vet_compradores: [],
      outro_valor_adicional: "Outros Valores",
    };
  },

  validations() {
    return {
      tabelas: {
        ttp_nome: { required },
      },
    };
  },

  computed: {
    filterFornecedor() {
      //variavel que recebe o valor do input
      const searchTerm = this.pesquisa_nome.toLowerCase();

      // filtra pelo codigo e pelo nome
      return this.fornecedoresReceb.filter((fornecedor) =>
        fornecedor.pes_apelido.toLowerCase().includes(searchTerm)
      );
    },
    // pesquisa por codigo
    filterFornecedorCodigo() {
      //variavel que recebe o valor do input
      const searchTerm = this.pesquisa_codigo.toLowerCase();
      // filtra pelo codigo e pelo nome
      return this.fornecedoresReceb.filter((fornecedor) =>
        fornecedor.pes_cpfcnpj.toLowerCase().includes(searchTerm)
      );
    },
  },
  created() {
    this.currentUser = JSON.parse(localStorage.getItem("user")); // para saber qual o usuario atual;
    this.currentEmpresa = JSON.parse(localStorage.getItem("currentEmpresa"));
    this.p_contas1 = this.p_contas;
    this.vet_compradores = this.compradores;
    this.pre_ped.estabilizada = 0;
    this.pre_ped.recebido = 0;
    this.pre_ped.faturado = 0;
    this.pre_ped.situacao_id = -3;
    this.locais_estoques2 = this.locais_estoques;
  },
  mounted() {
    this.setNewTitle();
    //this.pre_ped.tipo_id = this.
    this.produtos = this.tipos_produtos;
    this.fornecedoresReceb = this.fornecedores;
    this.list_products = this.produtos2; /////////////
    // console.log(this.list_products)
    //console.log(this.p_contas1)
    this.situacao_estabelecida();
  },
  methods: {
    verifica_inputs_danger() {
      let resp;
      if (
        this.tipo_selecionado != null &&
        this.pesquisa_codigo != null &&
        this.pre_ped.data &&
        this.pre_ped.previsao_chegada != null &&
        this.pre_ped.nnf != null &&
        this.pre_ped.valor != null &&
        this.Buyer != null
      ) {
        resp = 1;
      } else [(resp = 0)];

      return resp;
    },
    seleciona_local(event) {
      console.log(event);
      this.item.local_estoque_id = event.id;
    },
    makeToast(variant = null, message = "Error generic") {
      this.counter++;
      this.$bvToast.toast(message, {
        title: `Notificação`,
        toaster: "b-toaster-bottom-right",
        variant: variant,
        solid: true,
        appendToast: true,
      });
    },
    add_item() {
      if (this.item.qtd == "") {
        this.makeToast("danger", "digite a quantidade");
      } else {
        if (this.item.qtd == null || this.item.preco == null) {
          this.makeToast("danger", "digite a quantidade ou preço");
        } else {
          this.oculta2();
          this.item.id++;
          let item2 = { ...this.item };
          let valor = item2.valor.replace(/\./g, "").replace(",", ".");
          this.soma = this.soma + parseFloat(valor);
          //console.log(this.formatterCurrBR(this.soma))
          this.total = this.formatterCurrBR(this.soma);
          item2.total = this.soma;
          //console.log(item2);
          if (typeof item2.valor == "string") {
            this.item22 = item2;
            let stringValor = this.item22.valor;
            let valorFlutuante = parseFloat(
              stringValor
                .replace("R$", " ")
                .replace(/\./g, "")
                .replace(",", ".")
            );
            item2.valor = valorFlutuante;
          }
          if (typeof item2.preco == "string") {
            this.item22 = item2;
            let stringValor = this.item22.preco;
            let valorFlutuante = parseFloat(
              stringValor
                .replace("R$", " ")
                .replace(/\./g, "")
                .replace(",", ".")
            );
            item2.preco = valorFlutuante;
          }
          if (
            item2.lote == null ||
            item2.lote == undefined ||
            item2.lote == ""
          ) {
            item2.lote = 0;
          }
          // adciona os valores aqui;
          //  this.outro_valor.desconto = this.new_convert_srting_float(this.outro_valor.desconto)
          //  this.outro_valor.frete = this.new_convert_srting_float(this.outro_valor.frete)
          //  this.outro_valor.funrural2 = this.new_convert_srting_float(this.outro_valor.funrural2)
          //  item2.desconto = this.outro_valor.desconto
          //  item2.frete = this.outro_valor.frete
          //  item2.funrural2 = this.outro_valor.funrural2
           this.items_push.push(item2);
           console.log("monstrando o add item deposi Dos valores adcionados")
           console.log(this.items_push)
          this.calcular_frete_desc_rural();
        }
      }
    },
    dlt_item(objeto1) {
      //console.log(objeto1.valor);
      let valor_srting = this.formatterCurrBR(objeto1.valor);
      // console.log(valor_srting);
      let valor = valor_srting
        .replace(" ", "")
        .replace(/\./g, "")
        .replace(",", ".");
      this.soma = this.soma - parseFloat(valor);
      // console.log(this.soma)
      this.total = this.formatterCurrBR(this.soma);
      //this.item.id--;
      //this.items_push.pop();
      this.items_push = this.items_push.filter(
        (objeto) => objeto.id !== objeto1.id
      );
      this.calcular_frete_desc_rural();
    },
    //  logica para quando selecionar outro produto antes de ir para o model;
    validar_composittion2() {
      this.validate_composition = true;
    },
    // logica para aparece o botão de composicao da
    // listagem de produto;
    validar_composittion() {
      this.validate_composition = !this.validate_composition;
    },
    Recebecodigo(codigo, id) {
      //console.log(id);
      const objetoEncontrado = this.fornecedoresReceb.find(
        (objeto) => objeto.id === id
      );
      this.inicial.fornecedor_id = objetoEncontrado;
      //console.log(objetoEncontrado)
      // colocar id aqui quando fzer o post, dessa forma : id
      this.pre_ped.fornecedor_id = objetoEncontrado.id;
      this.pre_ped.objeto_fornecedor_id = objetoEncontrado;
      this.codigo = codigo;
      this.pesquisa_codigo = codigo;
      this.pesquisa_nome = objetoEncontrado.pes_apelido;
      this.nome = objetoEncontrado.pes_apelido.pes_apelido;
      // console.log(index)
    },
    //  busca a composicação pelo id do produto
    // pois esse tabela tem um relacionamento com composição;
    async composicao_produto(produto) {
      let response = await http.get("/composicao/pesquisa?id=" + produto.id);

      if (response.status === 200) {
        // alert("entrou");
        this.list_composicoes = response.data;
        // console.log(this.list_composicoes);

        if (this.validate_composition == false) {
          this.validar_composittion();
        }
      } else {
        console.log("naao entrou");
      }
    },
    RecebeNome(nome, id) {
      const objetoEncontrado = this.fornecedoresReceb.find(
        (objeto) => objeto.id === id
      );
      this.nome = nome;
      this.pesquisa_nome = this.nome;
      this.pesquisa_codigo = objetoEncontrado.pes_cpfcnpj;
      this.coidgo = objetoEncontrado.pes_cpfcnpj;
      //  console.log(this.nome);
    },
    // pega o produto da listagem de produtos;
    func_pegar(produto) {
      // usado para sumir o botão e so aparecer quando a requsição for respondida ;
      // que é a resição da função composicao_produto
      this.validate_composition = false;
      // console.log(produto.id);
      this.valor_select_prod = produto;
      this.composicao_produto(produto);
    },
    mostrar_composicao() {
      // console.log(this.valor_select_prod);
      this.nome_produto = this.valor_select_prod.produto_nome;
    },
    alter_value(value_order) {
      this.value_order = value_order.target.value;
    },
    setNewTitle() {
      this.$emit("newTitle", this.titleBody);
    },
    oculta() {
      
      this.hide = !this.hide;
      this.submit_item = false;
    },
    // usado no salvar
    oculta2() {
      this.submit_item = true;
      this.hide = false;
    },
    // usado no segundo adicionar
    oculta3() {
      this.submit_item = false;
      this.hide = false;
    },
    func_hide() {
      //this.hide1 =!this.hide1
      setTimeout(() => {
        // Código que será executado após o atraso de 3 segundos
        if (this.pesquisa_codigo != "") {
          this.hide1 = true;
        } else {
          this.hide1 = false;
        }
        //console.log('Função atrasada executada');
      }, 2000);
    },
    func_hide2() {
      setTimeout(() => {
        // Código que será executado após o atraso de 3 segundos
        if (this.pesquisa_nome != "") {
          this.hide2 = true;
        } else {
          this.hide2 = false;
        }
        //console.log('Função atrasada executada');
      }, 2000);
    },
    // para o nome;
    func_hide3() {
      if (this.hide2) {
        this.hide2 = false;
      }
    },
    // para codigo
    func_hide4() {
      if (this.hide1) {
        this.hide1 = false;
      }
    },
    async doPost(pre_pedido) {
      pre_pedido.empresa_id = this.currentEmpresa.id;
      pre_pedido.user_id = this.currentUser.id;
      try {
        let response = await http.post(
          "/pre-pedido?empresa_id=" + this.currentEmpresa.id,
          pre_pedido
        );
        if (response) {
          //this.offLoader();
          if (response.status === 200) {
            //  console.log("Sucesso");
            //this.getData();
            //this.abri_menu_modal()
            //this.inserindo = false;
            this.makeToast("success", "Registro incluído");
          }
        }
      } catch (error) {
        //this.offLoader();
        if (error.response) {
          if (error.response.status === 404) {
            this.makeToast("danger", "Destino URL não encontrado!");
          } else {
            this.makeToast("danger", error);
          }
        }
      }
    },

    voltar() {
      this.$emit("back");
    },
    selec_tipo(objeto) {
      //   console.log(objeto)
      this.pre_ped.tipo_id = objeto.id;
      this.inicial.tipo_id = objeto.id;

      if (objeto.id == 3) {
        this.tipo_bollean = true;
        this.fields = [];
        this.fields = [
          {
            label: "ID",
            key: "id",
            tdClass: "text-right",
            thClass: "text-center",
          },
          {
            label: "Produto",
            key: "mt_prima",
            tdClass: "text-left",
            thClass: "text-center",
          },
          {
            label: "Lote",
            key: "lote",
            tdClass: "text-left",
            thClass: "text-center",
          },
          {
            label: "Qantidade",
            key: "qtd",
            tdClass: "text-right",
            thClass: "text-center",
            formatter: "formatterCurrBR",
          },
          {
            label: "preço",
            key: "preco",
            tdClass: "text-right",
            thClass: "text-center",
          },
          {
            label: "valor",
            key: "valor",
            tdClass: "text-right",
            thClass: "text-center",
          },
          {
            label: "Ações",
            key: "excluir",
            tdClass: "text-center",
            thClass: "text-center",
          },
        ];
      } else {
        this.tipo_bollean = false;
        this.fields = [];
        this.fields = [
          {
            label: "ID",
            key: "id",
            tdClass: "text-right",
            thClass: "text-center",
          },
          {
            label: "Materia Prima",
            key: "mt_prima",
            tdClass: "text-left",
            thClass: "text-center",
          },
          {
            label: "Lote",
            key: "lote",
            tdClass: "text-left",
            thClass: "text-center",
          },
          {
            label: "Qantidade",
            key: "qtd",
            tdClass: "text-right",
            thClass: "text-center",
            formatter: "formatterCurrBR",
          },
          {
            label: "preço",
            key: "preco",
            tdClass: "text-right",
            thClass: "text-center",
          },
          {
            label: "valor",
            key: "valor",
            tdClass: "text-right",
            thClass: "text-center",
          },
          {
            label: "Ações",
            key: "excluir",
            tdClass: "text-center",
            thClass: "text-center",
          },
        ];
      }
    },
    selec_comprador(objeto) {
      // quando for fazer o post  muda isso para objeto.id
      this.pre_ped.comprador_id = objeto.id;
      this.pre_ped.objeto_comprador_id = objeto;
    },
    formatInput_valor() {
      // Remova todos os caracteres não numéricos do valor de entrada
      let numericValue = this.pre_ped.valor.replace(/\D/g, "");
      // Formate o valor numérico para a moeda desejada (por exemplo, BRL)
      this.pre_ped.valor = parseFloat(numericValue / 100)
        .toLocaleString("pt-BR", {
          style: "currency",
          currency: "BRL",
        })
        .replace("R$", " ");
    },
    coverter_float() {
      //typeof(this.pre_ped.valor)
      if (typeof this.pre_ped.valor == "string") {
        this.pre_ped2 = this.pre_ped;
        let stringValor = this.pre_ped2.valor;
        let valorFlutuante = parseFloat(
          stringValor.replace("R$", " ").replace(/\./g, "").replace(",", ".")
        );
        this.pre_ped.valor = valorFlutuante;
      }
      if (typeof this.item.preco == "string") {
        this.item3 = this.item;
        let stringValor = this.item3.preco;
        let valorFlutuante = parseFloat(
          stringValor.replace("R$", " ").replace(/\./g, "").replace(",", ".")
        );
        this.item.preco = valorFlutuante;
      }

      // this.outro_valor.desconto = this.new_convert_srting_float(this.outro_valor.desconto)
      // this.outro_valor.frete = this.new_convert_srting_float(this.outro_valor.frete)
      // this.outro_valor.funrural2 = this.new_convert_srting_float(this.outro_valor.funrural2)
    },
    outros_valores(event) {
      //console.log(event);
      console.log("mostrnado vetor de itens")
      

     

      this.fun_rural();

      if (event == 1) {
        this.tot = "subtotal";
        this.outros = true;
      } else {
        this.tot = "total";
        this.outros = false;
      }
    },
    fun_rural(event) {
      if (event == 1) {
        this.outro_valor_adicional = "Funrual";
        this.sigla = "-";
        this.input_disabled = false;
      } else {
        this.outro_valor_adicional = "Outros Valores";
        // console.log(this.total2);
        this.sigla = "+";
        this.input_disabled = true;
        // this.soma_valores_outros()
      }
    },
    soma_valores_outros() {
      // if(this.input_disabled == true){
      //     console.log(this.total2)
      // }
    },
    // outro_valor:{
    //     frete: null,
    //     desconto:null,
    //     funrural1:null,
    //     funrural2:null,
    //   }
    formatInput_frete() {
      // Remova todos os caracteres não numéricos do valor de entrada
      let numericValue = this.outro_valor.frete.replace(/\D/g, "");
      // Formate o valor numérico para a moeda desejada (por exemplo, BRL)
      this.outro_valor.frete = parseFloat(numericValue / 100)
        .toLocaleString("pt-BR", {
          style: "currency",
          currency: "BRL",
        })
        .replace("R$", " ");
        this.items_push[this.items_push.length - 1].frete = this.new_convert_srting_float(this.outro_valor.frete)
      this.calcular_frete_desc_rural();
    },
    formatInput_desconto() {
      // Remova todos os caracteres não numéricos do valor de entrada
      let numericValue = this.outro_valor.desconto.replace(/\D/g, "");
      // Formate o valor numérico para a moeda desejada (por exemplo, BRL)
      this.outro_valor.desconto = parseFloat(numericValue / 100)
        .toLocaleString("pt-BR", {
          style: "currency",
          currency: "BRL",
        })
        .replace("R$", " ");
        
      this.items_push[this.items_push.length - 1].desconto = this.new_convert_srting_float(this.outro_valor.desconto)
      this.calcular_frete_desc_rural();
    },
    formatInput_funrural1() {
      // Remova todos os caracteres não numéricos do valor de entrada
      let numericValue = this.outro_valor.funrural1.replace(/\D/g, "");
      // Formate o valor numérico para a moeda desejada (por exemplo, BRL)
      this.outro_valor.funrural1 = parseFloat(numericValue / 100)
        .toLocaleString("pt-BR", {
          style: "currency",
          currency: "BRL",
        })
        .replace("R$", " ");
      this.calcular_frete_desc_rural();
    },
    formatInput_funrural2() {
      // Remova todos os caracteres não numéricos do valor de entrada
      let numericValue = this.outro_valor.funrural2.replace(/\D/g, "");
      // Formate o valor numérico para a moeda desejada (por exemplo, BRL)
      this.outro_valor.funrural2 = parseFloat(numericValue / 100)
        .toLocaleString("pt-BR", {
          style: "currency",
          currency: "BRL",
        })
        .replace("R$", " ");
        this.items_push[this.items_push.length - 1].funrural2 = this.new_convert_srting_float(this.outro_valor.funrural2)
      this.calcular_frete_desc_rural();
    },
    formatInput_preco() {
      // Remova todos os caracteres não numéricos do valor de entrada
      let numericValue = this.item.preco.replace(/\D/g, "");
      // Formate o valor numérico para a moeda desejada (por exemplo, BRL)
      this.item.preco = parseFloat(numericValue / 100)
        .toLocaleString("pt-BR", {
          style: "currency",
          currency: "BRL",
        })
        .replace("R$", " ");
      this.calcular();
    },
    calcular() {
      // console.log("calucuar");
      //console.log("preço: " + this.item.preco);
      //console.log("Quantidade: " + this.item.qtd);

      if (this.item.qtd != null && this.item.preco != null) {
        let qtd = this.item.qtd;
        let preco = this.item.preco.replace(/\./g, "").replace(",", ".");
        let total = parseFloat(preco) * parseFloat(qtd);
        this.item.valor = this.formatterFloatBR(total, 4);
        this.total = this.formatterCurrBR(total);
        this.total2 = this.formatterCurrBR(total);
        this.subtotal = this.formatterCurrBR(total);
        //console.log(total);
      }
    },
    calcular_frete_desc_rural() {
      let frete = this.outro_valor.frete;
      let desconto = this.outro_valor.desconto;
      //let funrual1 = this.outro_valor.funrual1
      // let funrual2 = this.outro_valor.funrual2
      // outro_valor.funrural2
      if (frete != null && desconto != null) {
        if (this.outro_valor.funrural2 == null) {
          let total = this.total.replace(/\./g, "").replace(",", ".");
          let frete1 = this.outro_valor.frete
            .replace(/\./g, "")
            .replace(",", ".");
          let desconto1 = this.outro_valor.desconto
            .replace(/\./g, "")
            .replace(",", ".");
          let vlr_final =
            parseFloat(total) + parseFloat(frete1) - parseFloat(desconto1);
          this.total2 = this.formatterCurrBR(vlr_final);
          this.pre_ped.total_pedidos = vlr_final;
        } else if (this.outro_valor.funrural2 != null) {
          if (this.sigla == "+") {
            let total = this.total.replace(/\./g, "").replace(",", ".");
            let fun_r002 = this.outro_valor.funrural2
              .replace(/\./g, "")
              .replace(",", ".");
            let frete1 = this.outro_valor.frete
              .replace(/\./g, "")
              .replace(",", ".");
            let desconto1 = this.outro_valor.desconto
              .replace(/\./g, "")
              .replace(",", ".");
            let vlr_final =
              parseFloat(total) +
              (parseFloat(frete1) - parseFloat(desconto1)) +
              parseFloat(fun_r002);
            this.total2 = this.formatterCurrBR(vlr_final);
            this.pre_ped.total_pedidos = vlr_final;
          } else if (this.sigla == "-") {
            let total = this.total.replace(/\./g, "").replace(",", ".");
            let fun_r002 = this.outro_valor.funrural2
              .replace(/\./g, "")
              .replace(",", ".");
            let frete1 = this.outro_valor.frete
              .replace(/\./g, "")
              .replace(",", ".");
            let desconto1 = this.outro_valor.desconto
              .replace(/\./g, "")
              .replace(",", ".");
            let vlr_final =
              parseFloat(total) +
              parseFloat(frete1) -
              (parseFloat(desconto1) + parseFloat(fun_r002));
            this.total2 = this.formatterCurrBR(vlr_final);
            this.pre_ped.total_pedidos = vlr_final;
          } //fim else
        } else {
          // não precisava desse else;
          let total = this.total.replace(/\./g, "").replace(",", ".");
          let frete1 = this.outro_valor.frete
            .replace(/\./g, "")
            .replace(",", ".");
          let desconto1 = this.outro_valor.desconto
            .replace(/\./g, "")
            .replace(",", ".");
          let vlr_final =
            parseFloat(total) + parseFloat(frete1) - parseFloat(desconto1);
          this.total2 = this.formatterCurrBR(vlr_final);
          this.pre_ped.total_pedidos = vlr_final;
        }
      } else if (frete == null && desconto != null) {
        if (this.outro_valor.funrural2 == null) {
          let total = this.total.replace(/\./g, "").replace(",", ".");
          let desconto2 = this.outro_valor.desconto
            .replace(/\./g, "")
            .replace(",", ".");
          let vlr_final = parseFloat(total) - parseFloat(desconto2);
          this.total2 = this.formatterCurrBR(vlr_final);
          this.pre_ped.total_pedidos = vlr_final;
        } else if (this.outro_valor.funrural2 != null) {
          if (this.sigla == "+") {
            let total = this.total.replace(/\./g, "").replace(",", ".");
            let fun022 = this.outro_valor.funrural2
              .replace(/\./g, "")
              .replace(",", ".");
            let desconto2 = this.outro_valor.desconto
              .replace(/\./g, "")
              .replace(",", ".");
            let vlr_final =
              parseFloat(total) - parseFloat(desconto2) + parseFloat(fun022);
            this.total2 = this.formatterCurrBR(vlr_final);
            this.pre_ped.total_pedidos = vlr_final;
          } else if (this.sigla == "-") {
            let total = this.total.replace(/\./g, "").replace(",", ".");
            let fun022 = this.outro_valor.funrural2
              .replace(/\./g, "")
              .replace(",", ".");
            let desconto2 = this.outro_valor.desconto
              .replace(/\./g, "")
              .replace(",", ".");
            let vlr_final =
              parseFloat(total) - (parseFloat(desconto2) + parseFloat(fun022));
            this.total2 = this.formatterCurrBR(vlr_final);
            this.pre_ped.total_pedidos = vlr_final;
          }
        } else {
          // não precisa desse else, mas deixei por garantia;
          let total = this.total.replace(/\./g, "").replace(",", ".");
          ///let frete1 = this.outro_valor.frete.replace(/\./g, "").replace(",", ".")
          let desconto2 = this.outro_valor.desconto
            .replace(/\./g, "")
            .replace(",", ".");
          let vlr_final = parseFloat(total) - parseFloat(desconto2);
          this.total2 = this.formatterCurrBR(vlr_final);
          this.pre_ped.total_pedidos = vlr_final;
        }
      } else if (frete != null && desconto == null) {
        if (this.outro_valor.funrural2 == null) {
          let total = this.total.replace(/\./g, "").replace(",", ".");
          let frete2 = this.outro_valor.frete
            .replace(/\./g, "")
            .replace(",", ".");
          //let desconto2 = this.outro_valor.desconto.replace(/\./g, "").replace(",", ".")
          let vlr_final = parseFloat(total) + parseFloat(frete2);
          this.total2 = this.formatterCurrBR(vlr_final);
          this.pre_ped.total_pedidos = vlr_final;
        } else if (this.outro_valor.funrural2 != null) {
          if (this.sigla == "+") {
            let total = this.total.replace(/\./g, "").replace(",", ".");
            let fun033 = this.outro_valor.funrural2
              .replace(/\./g, "")
              .replace(",", ".");
            let frete2 = this.outro_valor.frete
              .replace(/\./g, "")
              .replace(",", ".");

            let vlr_final =
              parseFloat(total) + parseFloat(frete2) + parseFloat(fun033);
            this.total2 = this.formatterCurrBR(vlr_final);
            this.pre_ped.total_pedidos = vlr_final;
          } else if (this.sigla == "-") {
            let total = this.total.replace(/\./g, "").replace(",", ".");
            let fun033 = this.outro_valor.funrural2
              .replace(/\./g, "")
              .replace(",", ".");
            let frete2 = this.outro_valor.frete
              .replace(/\./g, "")
              .replace(",", ".");
            //let desconto2 = this.outro_valor.desconto.replace(/\./g, "").replace(",", ".")
            let vlr_final =
              parseFloat(total) + parseFloat(frete2) - parseFloat(fun033);
            this.total2 = this.formatterCurrBR(vlr_final);
            this.pre_ped.total_pedidos = vlr_final;
          }
        } else {
          let total = this.total.replace(/\./g, "").replace(",", ".");
          let frete2 = this.outro_valor.frete
            .replace(/\./g, "")
            .replace(",", ".");
          //let desconto2 = this.outro_valor.desconto.replace(/\./g, "").replace(",", ".")
          let vlr_final = parseFloat(total) + parseFloat(frete2);
          this.total2 = this.formatterCurrBR(vlr_final);
          this.pre_ped.total_pedidos = vlr_final;
        }
      } else {
        if (this.outro_valor.funrural2 == null) {
          let total = this.total.replace(/\./g, "").replace(",", ".");
          let vlr_final = parseFloat(total);
          this.total2 = this.formatterCurrBR(vlr_final);
          this.pre_ped.total_pedidos = vlr_final;
        } else if (this.outro_valor.funrural2 != null) {
          if (this.sigla == "+") {
            let total = this.total.replace(/\./g, "").replace(",", ".");
            let fun112 = this.outro_valor.funrural2
              .replace(/\./g, "")
              .replace(",", ".");
            //let desconto2 = this.outro_valor.desconto.replace(/\./g, "").replace(",", ".")
            let vlr_final = parseFloat(total) + parseFloat(fun112);
            this.total2 = this.formatterCurrBR(vlr_final);
            this.pre_ped.total_pedidos = vlr_final;
          } else if (this.sigla == "-") {
            let total = this.total.replace(/\./g, "").replace(",", ".");
            let fun112 = this.outro_valor.funrural2
              .replace(/\./g, "")
              .replace(",", ".");
            //let desconto2 = this.outro_valor.desconto.replace(/\./g, "").replace(",", ".")
            let vlr_final = parseFloat(total) - parseFloat(fun112);
            this.total2 = this.formatterCurrBR(vlr_final);
            this.pre_ped.total_pedidos = vlr_final;
          }
        } else {
          let total = this.total.replace(/\./g, "").replace(",", ".");
          let vlr_final = parseFloat(total);
          this.total2 = this.formatterCurrBR(vlr_final);
          this.pre_ped.total_pedidos = vlr_final;
        }
      }

      // if(this.sigla == '+'){

      //   let total = this.total2

      //   if(typeof total === 'string'){

      //       if(total.includes('.') && total.includes(',') ){
      //           total = parseFloat(total.replace('.','').replace(',','.'))
      //           //this.total2 = this.formatterCurrBR(total)
      //       }else if((!total.includes('.')) && total.includes(',')){
      //         total = parseFloat(total.replace(',','.'))
      //         //this.total2 = this.formatterCurrBR(total)
      //       }
      //     }else {
      //       total = this.total2
      //     }
      //       let fun = this.outro_valor.funrural2
      //       if(typeof fun === 'string'){
      //       if(fun.includes('.') && fun.includes(',') ){
      //           fun = parseFloat(fun.replace('.','').replace(',','.'))
      //           //this.fun2 = this.formatterCurrBR(fun)
      //       }else if((!fun.includes('.')) && fun.includes(',')){
      //         fun = parseFloat(fun.replace(',','.'))
      //         //this.total2 = this.formatterCurrBR(total)
      //       }
      //     }else {
      //       fun = this.outro_valor.funrural2
      //     }
      //     //  let novo_total =  total + fun

      //     //console.log(total)

      //     if(fun != null && fun != undefined && fun != '' && (!isNaN(fun)) ){
      //       console.log(total + (fun+ fun))
      //     //  this.total2 =  (total + (fun+ fun))

      //     }

      // }
    },
    setPost() {
      //this.tabelas
      //this.coverter_float();
      //console.log(this.total2)
      //this.abri_menu_modal();
      // this.pre_ped.total_pedidos =]
      
      if (this.verifica_inputs_danger() == 0) {
        this.makeToast("danger","Preencha os Valores em Vermelho")
      }else{
        
        this.coverter_float();
        // this.pre_ped.desconto = this.outro_valor.desconto
        // this.pre_ped.frete = this.outro_valor.frete
        // this.pre_ped.funrural2 = this.outro_valor.funrural2
        this.pre_ped.vet = this.items_push;
        console.log("Mostrnado vetor no set Post vet ")
        console.log(this.pre_ped.vet)
        this.$emit('doPost',this.pre_ped);
      }
      
      //  this.voltar();
      //this.$emit("doPost", this.pre_ped); // mudar isso aqui//
      // deve receber dos inputs;
    },
    menu2_open() {
      if (this.verifica_inputs_danger() == 0) {
        this.makeToast('danger','Preencha os Valores em Vermelho')
      } else {
        this.coverter_float();
        this.pre_ped.vet = this.items_push;
        console.log(
          "estou no meunu2 da tela de insert mostrando o vettor em prep"
        );
        console.log(this.pre_ped.vet);
        this.$emit("menu2_open", this.pre_ped, this.items_push, this.item);
      }
    },
    abri_menu_modal() {
      this.menu_abre = true;
      //this.showModal = false;
      //console.log(this.menu_abre);
    },
    back() {
      this.menu_abre = false;
    },

    situacao_estabelecida() {
      this.pre_ped.situacao_id;

      if (this.pre_ped.situacao_id == 1) {
        this.pre_ped.estabilizada = 1;
        this.pre_ped.recebido = 1;
      } else {
        this.pre_ped.estabilizada = 0;
        this.pre_ped.recebido = 0;
      }
    },

    seleciona_materiaProduto(event) {
      // console.log(event);
      this.item.mt_prima = event.produto_nome;
      this.item.produto_id = event.id;
    },

    sit_confirmada() {
      if (this.pre_ped.situacao_id == 0) {
        this.pre_ped.recebido = 1;
      } else {
        this.pre_ped.recebido = 0;
      }
    },
  },
};
</script>

<template>
  <!--  pre_ped: {
        tipo_id: 1,
        fornecedor_id: 1,
        data: '2023/05/03',
        previsao_chegada: '2022/05/03',
        nnf: 'nota',
        valor: 12,
        situacao_id: 1,
        comprador_id: 1,
        obervacoes: 'nada',
      }, -->
  <div class="card">
    <b-modal
      v-model="showModal"
      id="modal-xl"
      size="xl"
      title="Cadastro Pedido de Compra"
      hide-footer
      @hidden="voltar()"
    >
      <div class="form-row form-group" role="group">
        <div class="col-sm-12 col-md-4">
          <div class="form-group">
            <label for="tipo_id" class="col-form-label">
              <b-badge
                data-name="Total"
                class="field-status fonte"
                :variant="
                  tipo_selecionado != null && tipo_selecionado != ''
                    ? 'success'
                    : 'danger'
                "
              >
                {{ "*Tipo" }}
              </b-badge>
            </label>
            <div class="col">
              <!-- label="descricao" -->
              <multiselect
                v-model="tipo_selecionado"
                @select="selec_tipo($event)"
                :options="tipos_produtos2"
                label="nome"
              ></multiselect>
            </div>
          </div>
        </div>
        <div class="col-sm-12 col-md-4">
          <div class="form-group">
            <label for="tipo_id" class="col-form-label">
              <b-badge
                data-name="Total"
                class="field-status fonte"
                :variant="
                  pesquisa_codigo != null && pesquisa_codigo != ''
                    ? 'success'
                    : 'danger'
                "
              >
                {{ "*Fornecedor" }}
              </b-badge>
            </label>
            <div class="col" id="fornecedor_id">
              <!--  @keyup.enter -->
              <input
                v-model="pesquisa_codigo"
                type="text"
                class="form-control text-left"
                placeholder="codigo"
                @input="func_hide"
              />
              <ul v-if="hide1">
                <li
                  v-for="(fornecedor, index) in filterFornecedorCodigo"
                  :key="fornecedor.id"
                >
                  <a
                    href="#"
                    @click="
                      Recebecodigo(
                        fornecedor.pes_cpfcnpj,
                        (index = fornecedor.id)
                      ),
                        func_hide4()
                    "
                    >{{ fornecedor.pes_cpfcnpj }}</a
                  >
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div class="col-sm-12 col-md-4">
          <div class="form-group">
            <label for="tipo_id" class="col-form-label">&nbsp;</label>
            <div class="col">
              <input
                v-model="pesquisa_nome"
                type="text"
                class="form-control text-left"
                placeholder="Pesquisar por nome"
                @input="func_hide2()"
              />
              <ul v-if="hide2">
                <li
                  v-for="(fornecedor, index) in filterFornecedor"
                  :key="fornecedor.id"
                >
                  <a
                    href="#"
                    @click="
                      RecebeNome(
                        fornecedor.pes_apelido,
                        (index = fornecedor.id)
                      ),
                        func_hide3()
                    "
                    >{{ fornecedor.pes_apelido }}</a
                  >
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div class="col-sm-12 col-md-4">
          <div class="form-group">
            <label for="tipo_id" class="col-form-label">&nbsp;</label>
            <div class="col">
              <div>
                <label for="data">
                  <b-badge
                    data-name="Total"
                    class="field-status fonte"
                    :variant="
                      pre_ped.data != null && pre_ped.data != ''
                        ? 'success'
                        : 'danger'
                    "
                  >
                    {{ "*Data do Pedido:" }}
                  </b-badge>
                </label>
                <b-form-datepicker
                  id="data"
                  v-model="pre_ped.data"
                  placeholder="selecionar Data"
                ></b-form-datepicker>
              </div>
            </div>
          </div>
        </div>
        <div class="col-sm-12 col-md-4">
          <div class="form-group">
            <label for="tipo_id" class="col-form-label">&nbsp;</label>
            <div class="col">
              <div>
                <label for="data">
                  <b-badge
                    data-name="Total"
                    class="field-status fonte"
                    :variant="
                      pre_ped.previsao_chegada != null &&
                      pre_ped.previsao_chegada != ''
                        ? 'success'
                        : 'danger'
                    "
                  >
                    {{ "*Previsão de chegada :" }}
                  </b-badge>
                </label>
                <b-form-datepicker
                  id="data_arrival"
                  v-model="pre_ped.previsao_chegada"
                  placeholder="selecionar Data"
                ></b-form-datepicker>
              </div>
            </div>
          </div>
        </div>
        <div class="col-sm-12 col-md-2">
          <div class="form-group">
            <label for="tipo_id" class="col-form-label">&nbsp;</label>
            <div class="col">
              <div>
                <label for="data">
                  <b-badge
                    data-name="Total"
                    class="field-status fonte"
                    :variant="
                      pre_ped.nnf != null && pre_ped.nnf != ''
                        ? 'success'
                        : 'danger'
                    "
                  >
                    {{ "*Nota Fiscal :" }}
                  </b-badge>
                </label>
                <input
                  v-model="pre_ped.nnf"
                  type="text"
                  class="form-control text-left"
                  placeholder="digite nota"
                />
              </div>
            </div>
          </div>
        </div>
        <div class="col-sm-12 col-md-2">
          <div class="form-group">
            <label for="tipo_id" class="col-form-label">&nbsp;</label>
            <div class="col">
              <div>
                <label for="data">
                  <b-badge
                    data-name="Total"
                    class="field-status fonte"
                    :variant="
                      pre_ped.valor != null && pre_ped.valor != ''
                        ? 'success'
                        : 'danger'
                    "
                  >
                    {{ "*Valor:" }}
                  </b-badge>
                </label>
                <input
                  v-model="pre_ped.valor"
                  @input="formatInput_valor()"
                  type="text"
                  class="form-control text-left"
                  placeholder="digite nota"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="form-row form-group" role="group">
        <div class="col-sm-12 col-md-12">
          <div class="form-group">
            <label for="tipo_id" class="col-form-label">&nbsp;</label>
            <div class="col">
              <div>
                <label for="data">itens:</label>
                <div id="borda">
                  <!-- tabela  -->
                  <button
                    class="btn btn-light"
                    id="btn1"
                    @click="oculta"
                    v-if="!hide"
                  >
                    <i class="fas fa-edit"></i> Adicionar Materia Prima
                  </button>
                  <div v-if="hide">
                    <div
                      v-if="!tipo_bollean"
                      class="form-row form-group"
                      role="group"
                    >
                      <div class="col-sm-12 col-md-2">
                        <div class="form-group">
                          <div class="col">
                            <div>
                              <input hidden type="text" v-model="item.id" />
                              <label for="data">Materia prima</label>
                              <multiselect
                                @select="seleciona_materiaProduto($event)"
                                v-model="Feedstock"
                                :options="list_products"
                                label="produto_nome"
                              >
                              </multiselect>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="col-sm-12 col-md-2">
                        <div class="form-group">
                          <div class="col">
                            <div>
                              <!-- criar um atributo para o lote -->
                              <label for="data">Lote</label>
                              <input
                                v-model="item.lote"
                                type="text"
                                class="form-control text-left"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="col-sm-12 col-md-2">
                        <div class="form-group">
                          <div class="col">
                            <div>
                              <!-- criar um atributo para o lote -->
                              <label for="data">Local de estoque</label>
                              <multiselect
                                @select="seleciona_local($event)"
                                v-model="select_estoques"
                                :options="locais_estoques2"
                                label="descricao"
                              >
                              </multiselect>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="col-sm-12 col-md-2">
                        <div class="form-group">
                          <div class="col">
                            <div>
                              <label for="data">Quantidade</label>
                              <input
                                @input="calcular()"
                                min="0"
                                type="number"
                                v-model="item.qtd"
                                class="form-control text-left"
                              />
                              <!-- <button class="btn btn-light" id="btn1" @click="oculta"><i class="fas fa-edit"></i> add item</button> -->
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="col-sm-12 col-md-2">
                        <div class="form-group">
                          <div class="col">
                            <div>
                              <label for="data">Preço</label>
                              <input
                                @input="formatInput_preco()"
                                v-model="item.preco"
                                class="form-control text-left"
                              />
                              <!-- <button class="btn btn-light" id="btn1" @click="oculta"><i class="fas fa-edit"></i> add item</button> -->
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="col-sm-12 col-md-2">
                        <div class="form-group">
                          <div class="col">
                            <div>
                              <label for="data">valor</label>
                              <input
                                v-model="item.valor"
                                disabled
                                type="text"
                                class="form-control text-left"
                              />
                              <!-- <button class="btn btn-light" id="btn1" @click="oculta"><i class="fas fa-edit"></i> add item</button> -->
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      v-if="tipo_bollean"
                      class="form-row form-group"
                      role="group"
                    >
                      <div class="col-sm-12 col-md-3">
                        <div class="form-group">
                          <div class="col">
                            <div>
                              <input hidden type="text" v-model="item.id" />
                              <label for="data">Produto</label>
                              <multiselect
                                @select="seleciona_materiaProduto($event)"
                                v-model="Feedstock"
                                :options="list_products"
                                label="produto_nome"
                              >
                              </multiselect>
                              <!--  -->
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="col-sm-12 col-md-2">
                        <div class="form-group">
                          <div class="col">
                            <div>
                              <!-- criar um atributo para o lote -->
                              <label for="data">Lote</label>
                              <input
                                v-model="item.lote"
                                type="text"
                                class="form-control text-left"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="col-sm-12 col-md-2">
                        <div class="form-group">
                          <div class="col">
                            <div>
                              <label for="data">Quantidade</label>
                              <input
                                @input="calcular()"
                                min="0"
                                type="number"
                                v-model="item.qtd"
                                class="form-control text-left"
                              />
                              <!-- <button class="btn btn-light" id="btn1" @click="oculta"><i class="fas fa-edit"></i> add item</button> -->
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="col-sm-12 col-md-2">
                        <div class="form-group">
                          <div class="col">
                            <div>
                              <label for="data">Preço</label>
                              <input
                                @input="formatInput_preco()"
                                v-model="item.preco"
                                class="form-control text-left"
                              />
                              <!-- <button class="btn btn-light" id="btn1" @click="oculta"><i class="fas fa-edit"></i> add item</button> -->
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="col-sm-12 col-md-3">
                        <div class="form-group">
                          <div class="col">
                            <div>
                              <label for="data">valor</label>
                              <input
                                v-model="item.valor"
                                disabled
                                type="text"
                                class="form-control text-left"
                              />
                              <!-- <button class="btn btn-light" id="btn1" @click="oculta"><i class="fas fa-edit"></i> add item</button> -->
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    v-if="hide"
                    class="row d-flex justify-content-center align-items-center"
                  >
                    <div class="col-sm-12 col-md-12 text-center">
                      <div>
                        <label for="data">&nbsp;</label>
                        <button class="btn btn-light" @click="add_item()">
                          <!-- se de erro adiconar o ocluta dois aqui -->
                          Adicionar
                        </button>
                      </div>
                    </div>
                  </div>
                  <!-- colocar as itens aqui -->
                  <!-- <label for="">Informações dos Items:</label> -->
                  <div v-if="!hide">
                    <b-table
                      striped
                      hover
                      :items="items_push"
                      responsive
                      id="itens"
                      :fields="fields"
                    >
                      <!-- <template #cell(data_criacao)="row">
                        <span class="text-right">{{
                          formatDateTimeBR(row.item.data_criacao)
                        }}</span>
                      </template> -->
                      <template #cell(valor)="row">
                        <span class="text-center">{{
                          formatterCurrBR(row.item.valor)
                        }}</span>
                      </template>
                      <template #cell(preco)="row">
                        <span class="text-right">{{
                          formatterCurrBR(row.item.preco)
                        }}</span>
                      </template>
                      <template #cell(excluir)="row">
                        <button
                          @click="dlt_item(row.item)"
                          class="btn btn-danger"
                        >
                          Excluir
                        </button>
                      </template>
                      <template #cell(local_estoque_id)="row">
                        {{ row.item.local_estoque_id.descricao }}
                      </template>
                      <template #cell(tipo_pagamento_id)="row">
                        {{
                          row.item.tipo_pagamento_id === 1
                            ? "Dinheiro"
                            : row.item.tipo_pagamento_id === 2
                            ? "Duplicata"
                            : row.item.tipo_pagamento_id === 3
                            ? "Cheque"
                            : row.item.tipo_pagamento_id === 4
                            ? "Cartão de Credito"
                            : row.item.tipo_pagamento_id === 5
                            ? "Cartão de Debito"
                            : row.item.tipo_pagamento_id === 6
                            ? "Cupom Devolução"
                            : row.item.tipo_pagamento_id === 7
                            ? "Transferencia"
                            : row.item.tipo_pagamento_id === 8
                            ? "Vale Alimentação"
                            : row.item.tipo_pagamento_id === 9
                            ? "Vale Refeição"
                            : row.item.tipo_pagamento_id === 10
                            ? "Vale Presente"
                            : row.item.tipo_pagamento_id === 11
                            ? "Vale Combustivel"
                            : row.item.tipo_pagamento_id === 12
                            ? "Sem Pagamento"
                            : "Outros"
                        }}
                      </template>
                    </b-table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row" v-if="submit_item">
        <div class="col-sm-12 col-md-12">
          <div class="card">
            <div class="card-body">
              <div class="row d-flex justify-content-right align-items-right">
                <div class="col-md-12 text-right">
                  <label for="" class="mr-2">{{ tot + "-> R$" }} </label>
                  <input v-model="total" type="text" class="estilo_input" />
                </div>
              </div>
              <div class="row d-flex justify-content-center align-items-center">
                <div class="col-md-12 text-center">
                  <b-form-checkbox
                    class="largura"
                    value="1"
                    id="checkbox6"
                    @change="outros_valores($event)"
                    >Adicionar Outros Valores
                  </b-form-checkbox>
                </div>
              </div>
              <div
                v-if="outros"
                class="row d-flex justify-content-right align-items-right"
              >
                <div class="col-md-12 text-right mb-2">
                  <label for="" class="mr-2"
                    >Frete -> {{ (seta = "+") }} {{ "R$" }}
                  </label>
                  <input
                    v-model="outro_valor.frete"
                    type="text"
                    class="estilo_input"
                    @input="formatInput_frete()"
                  />
                </div>
                <div class="col-md-12 text-right mb-2">
                  <label for="" class="mr-2">
                    Desconto -> {{ (seta = "-") }} {{ "R$" }}
                  </label>
                  <input
                    v-model="outro_valor.desconto"
                    type="text"
                    class="estilo_input"
                    @input="formatInput_desconto()"
                  />
                </div>
                <!-- @change="funrural($event)" -->
                <div class="col-md-12 text-right">
                  <b-form-checkbox value="1" @change="fun_rural($event)">
                    Definir FunRural
                    <!-- :disabled="input_disabled" -->
                    <!-- @input="formatInput_funrural1()" -->
                    <input
                      v-model="outro_valor_adicional"
                      type="text"
                      class="estilo_input"
                      placeholder="Digite aqui"
                    />
                    <label for="" class="mr-2"
                      >{{ "-> " }}{{ sigla }}{{ " R$" }}</label
                    >
                    <!-- :disabled="input_disabled" -->
                    <input
                      placeholder="Digite"
                      type="text"
                      class="estilo_input"
                      v-model="outro_valor.funrural2"
                      @input="formatInput_funrural2()"
                    />
                  </b-form-checkbox>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        v-if="outros"
        class="row d-flex justify-content-right align-items-right"
      >
        <div class="col-md-12 text-right">
          <div class="card">
            <div class="card-body">
              <div class="row">
                <div class="col-md-12">
                  <label for="" class="mr-2">Total -> R$</label>
                  <input v-model="total2" type="text" class="estilo_input" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="form-row form-group" role="group">
        <div class="col-sm-12 col-md-6">
          <div class="form-group">
            <label for="tipo_id" class="col-form-label">&nbsp;</label>
            <div class="col">
              <div>
                <label for="data">Situação:</label>
                <div class="form-check">
                  <input
                    v-model="pre_ped.situacao_id"
                    type="radio"
                    name="classe"
                    id="classe1"
                    value="0"
                    class="form-check-input"
                    @change="sit_confirmada()"
                  />
                  <label for="classe1" class="form-check-label"
                    >Cosiderar que os produtos já foram recebidos</label
                  >
                </div>
                <div class="form-check">
                  <input
                    v-model="pre_ped.situacao_id"
                    type="radio"
                    name="classe"
                    id="classe1"
                    value="1"
                    class="form-check-input"
                    @change="situacao_estabelecida()"
                  />
                  <label for="classe1" class="form-check-label"
                    >Cosiderar que a compra ja está confirmada e
                    estabelecida</label
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-sm-12 col-md-4">
          <div class="form-group">
            <label for="tipo_id" class="col-form-label">&nbsp;</label>
            <div class="col">
              <div>
                <label for="data">
                  <b-badge
                    data-name="Total"
                    class="field-status fonte"
                    :variant="
                      Buyer != null && Buyer != '' ? 'success' : 'danger'
                    "
                  >
                    {{ "*Comprador:" }}
                  </b-badge>
                </label>
                <multiselect
                  v-model="Buyer"
                  @select="selec_comprador($event)"
                  :options="vet_compradores"
                  label="pes_fantasia"
                ></multiselect>
              </div>
            </div>
          </div>
        </div>
        <div class="col-sm-12 col-md-6">
          <div class="form-group">
            <label for="tipo_id" class="col-form-label"
              >Listagem de Produtos</label
            >
            <div class="col">
              <multiselect
                v-model="produto_selecionado"
                :options="list_products"
                label="produto_nome"
                @select="func_pegar($event)"
                aria-placeholder="selecione"
              >
              </multiselect>
              <div v-if="validate_composition">
                <button
                  class="btn btn-light"
                  @click="mostrar_composicao(), validar_composittion2()"
                  v-b-modal.modal-xl
                >
                  Composição
                </button>
              </div>
              <div>
                <b-modal
                  id="modal-xl"
                  size="xl"
                  :title="nome_produto"
                  @hidden="validar_composittion()"
                >
                  <composicao
                    :composicoes="list_composicoes"
                    :produto="valor_select_prod"
                  />
                </b-modal>
              </div>
            </div>
          </div>
        </div>
        <div class="col-sm-12 col-md-6">
          <div class="form-group">
            <!-- <label for="tipo_id" class="col-form-label">&nbsp;</label> -->
            <div class="col">
              <div>
                <label for="data">Observações :</label>
                <b-form-textarea
                  v-model="pre_ped.obervacoes"
                  id="texto"
                ></b-form-textarea>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="form-row form-group" role="group">
        <div class="card-body">
          <div class="col-md-12 mt-10">
            <!-- abri_menu_modal() -->
            <!--  -->
            <button class="btn btn-light" @click.prevent="setPost()">
              Gravar
            </button>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-md-12"></div>
      </div>
    </b-modal>
    <!-- <div class="card-body">
                      <i
                        v-if="loader.get"
                        class="fa fa-spinner fa-5x fa-spin text-success text-center"
                      ></i>
                      <form id="form-tipo-movimento" role="form" class="form-horizontal">
                        <b-card no-body class="mb-1">
                          <b-card-body>
                            <h4>Tipo de Tabelas de Preço</h4>
                            <div class="form-row form-group" role="group">
                              <div class="col-sm-12 col-md-3">
                                <div class="form-group">
                                  <label for="tipo_sigla_id" class="col-form-label">Nome</label>
                                  <div class="col">
                                    <input
                                      v-model="tabelas.ttp_nome"
                                      class="form-control text-leftt"
                                      type="text"
                                      placeholder="digite a Sigla"
                                      id="sigla"
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </b-card-body>
                        </b-card>
                      </form>
                    </div> -->
  </div>
</template>

<style scoped>
.multiselect-wrapper {
  display: flex;
  align-items: center;
}

.multiselect-wrapper button {
  margin-left: 10px;
}

#borda {
  border: 1px solid #dee2e6; /* Cor da borda ajustada para uma cor mais leve, similar ao Bootstrap */
  border-radius: 0.375rem; /* Ajuste do border-radius para 0.375rem, um valor comum no Bootstrap */
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15); /* Sombra mais pronunciada */
  padding: 1rem; /* Preenchimento interno */
}

#btn1 {
  margin-left: 40%;
}

.fa-edit {
  color: blue;
}
.estilo_input {
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
</style>